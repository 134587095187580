<template>
<div style="width:100%">
  <v-sheet :color="colores.primario" style="padding:1px" rounded v-if="this.session.area === 17 && this.escritura" >
    <v-card flat>
      <v-toolbar dense flat><span :style="{'color': colores.primario}" style="font-size:20px"><b>CALIDAD</b></span></v-toolbar>
      <v-card-text class="pt-0">
     <v-form>
      <template>
    <v-row  v-for="(data) in this.preguntas" :key="data.id">
      <v-col v-if="data.json_data.tipo === 'select'" cols="12"  class="pr-1">
      <v-select
      :items="data.json_data.respuestas"
      :label ="data.json_data.titulo"
      @change="onChange($event,data.id,data.json_data.calificable,data.json_data.titulo)"

      >
      </v-select>
    </v-col>
      <v-col v-if="data.json_data.tipo === 'textArea'">
         <v-flex xs12 sm12 md12>
                          <v-textarea
                            :label="data.json_data.titulo"
                            required
                            :rules="validarVacio"
                            @change="onChange($event,data.id,data.json_data.calificable,data.json_data.titulo)"

                          ></v-textarea>
            </v-flex>
      </v-col>
    </v-row>
    {{ respuesta_array }}
           <v-row>
            <v-col>
              <v-btn
              block
             color="primary"
              elevation="8"
              @click="guardar"
              >Guardar</v-btn>
             </v-col>
           </v-row>
          </template>
        </v-form>
      </v-card-text>
    </v-card>
  </v-sheet>
</div>
</template>

<script>
import recCalidad from '@/js/rec_calidad.js'
import recFunciones from '@/js/rec_funciones.js'
export default {
  name: 'formCalidadComponent',
  components: {
  },
  mixins: [recCalidad, recFunciones],
  created () {
    this.rec_consultar_gestion_calidad(this.gestion_id)
    this.rec_preguntas_calidad()
  },
  props: ['colores', 'rules', 'items', 'gestion_id', 'session'],
  data: () => ({
    validarVacio: [(v) => !!v || 'Por favor responda esta pregunta.'],
    preguntas: [],
    respuesta_array: [],
    total: '',
    escritura: false,
    bandera: false,
    respuesta: []
  }),
  methods: {
    onChange (event, id, calificable, titulo) {
      this.bandera = false
      const arrayVacio = (arr) => !Array.isArray(arr) || arr.length === 0
      if (arrayVacio(this.respuesta_array)) {
        this.respuesta_array.push({ event, id, calificable, titulo })
        this.bandera = true
      } else {
        this.respuesta_array.forEach(element => {
          if (element.id === id) {
            element.event = event
            this.bandera = true
          }
        })
      }
      if (!this.bandera) {
        this.respuesta_array.push({ event, id, calificable, titulo })
      }
    },
    guardar () {
      this.respuesta_array.sort((a, b) => {
        return a.id - b.id
      })
      for (const key in this.respuesta_array) {
        if (Object.hasOwnProperty.call(this.respuesta_array, key)) {
          const element = this.respuesta_array[key]
          this.respuesta.push({ pregunta: element.titulo, respuesta: element.event })
          if (element.event === 'Sí') {
            this.total++
          }
        }
      }
      this.respuesta.push({ nota: this.total * 20 + '%' })
      this.respuesta.push({ auditor: this.session.nombres + ' ' + this.session.apellidos })
      // this.respuesta = JSON.stringify(this.respuesta)
      this.rec_guardar_calidad(this.gestion_id, this.respuesta)
      this.escritura = false
      this.$emit('lecturaCalidadEmit')
    }
  }
}
</script>
