import axios from 'axios'
export default {
  methods: {
    rec_get_asesor (areaid) {
      const urlroute = this.$store.getters.getUrl + 'asesores_area/' + areaid
      this.users = []
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.users = response.data
        if (areaid === 5) {
          for (let index = 0; index < this.users.length; index++) {
            const element = this.users[index]
            if (element.id_usuario !== 42568) {
              this.users = []
              this.users.push(element)
              break
            }
          }
        }
        for (let index = 0; index < this.users.length; index++) {
          const element = this.users[index]
          element.usuario.nombre_usuario = element.usuario.nombre_usuario + ' ' + element.usuario.apellido_usuario
        }
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        // this.loadingArea = false
      })
    },
    rec_bandeja_mis_pqrs_calidad (usuarioId, areaid, anio, mes) {
      let message = ''
      let errorEstado = false
      if (usuarioId > 0) {
        const urlroute = this.$store.getters.getUrl + 'badeja_mis_pqrs_calidad/'
        axios({
          url: urlroute,
          method: 'POST',
          data: { escaladoa_id: usuarioId, area: areaid, anio: anio, mes: mes },
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        }).then(response => {
          this.pqrsUser = response.data
        }).catch(function (error) {
          errorEstado = true
          message = error.response.data.message
        }).finally(() => {
          this.esperar = false
          if (errorEstado) {
            this.dialogAlertaError = true
            this.alertError()
            this.message = message
          }
        })
      }
    },
    rec_asignar_pqrs_calidad (pqrsUser, asesorId, supervisorId) {
      if (asesorId > 0) {
        const urlroute = this.$store.getters.getUrl + 'asignar_PQR_calidad/'
        axios({
          url: urlroute,
          method: 'POST',
          data: { pqrsUser: pqrsUser, usuario_gest: asesorId, usuario_asig: supervisorId },
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          }
        }).then(response => {
          this.pqrsUser = response.data
        }).catch(error => {
          console.log('error ' + error)
        }).finally(() => {
          // this.loadingregistro = false
        })
      }
    },
    rec_preguntas_calidad () {
      const urlroute = this.$store.getters.getUrl + 'list_preguntas_calidad/'
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.preguntas = response.data
        if (this.preguntas.length > 0) {
          for (let index = 0; index < this.preguntas.length; index++) {
            const element = this.preguntas[index]
            this.preguntas[index].json_data = JSON.parse(element.json_data)
            this.preguntas[index].json_data.respuestas = Object.values(this.preguntas[index].json_data.respuestas)
          }
        }
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        // this.loadingregistro = false
      })
    },
    rec_guardar_calidad (idQR, jsonData) {
      const array = []
      for (let i = 0; i < jsonData.length; i++) {
        if (jsonData[i].pregunta !== undefined && jsonData[i].pregunta === 'COMENTARIOS') {
          jsonData[i].respuesta = this.rec_function_replaceall(jsonData[i].respuesta, ',', ' ')
          // const newtexto = this.rec_function_replaceall(jsonData[i].respuesta, ',', '$$$$$$$$$$')
          // const newtexto = this.rec_function_replaceall(jsonData[i].respuesta, ',', ' ')
          // array.push({ pregunta: 'COMENTARIOS', respuesta: newtexto })
          array.push(jsonData[i])
        } else {
          array.push(jsonData[i])
        }
      }
      const respuesta = JSON.stringify(array)
      const urlroute = this.$store.getters.getUrl + 'guardar_preguntas_calidad/'
      axios({
        url: urlroute,
        method: 'POST',
        // data: { gestion_id: idQR, json_respu: jsonData },
        data: { gestion_id: idQR, json_respu: respuesta },
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.preguntas = response.data
        if (this.preguntas.length > 0) {
          for (let index = 0; index < this.preguntas.length; index++) {
            const element = this.preguntas[index]
            this.preguntas[index].json_data = JSON.parse(element.json_data)
          }
        }
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        // this.loadingregistro = false
      })
    },
    rec_consultar_gestion_calidad (gestId) {
      const urlroute = this.$store.getters.getUrl + 'list_gest_calidad/' + gestId
      axios({
        url: urlroute,
        method: 'GET',
        data: null,
        headers: {
          Authorization: 'Bearer ' + this.$store.getters.getToken
        }
      }).then(response => {
        this.gestion = response.data
        for (let index = 0; index < this.gestion.length; index++) {
          const element = this.gestion[index]
          if (this.gestion[index].json_respu !== null) {
            this.gestion[index].json_respu = JSON.parse(element.json_respu)
            this.lectura = true
            this.escritura = false
          } else {
            this.lectura = false
            this.escritura = true
          }
        }
      }).catch(error => {
        console.log('error ' + error)
      }).finally(() => {
        // this.loadingregistro = false
      })
    }
  }
}
